import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts/Layout";
import SiteMetadata from "../components/SiteMetadata";
import IdeaList from "../components/IdeaList/IdeaList";
import EventList from "../components/EventList";
import HeadingTop from "../components/BuildingBlocks/HeadingTop";

const GeschenkPage = ({ data, location }) => {
  const { state } = location;
  const person = state?.person;
  const event = state?.event;
  return (
    <Layout>
      <SiteMetadata
        title="Handverlesene Geschenkideen"
        description="Lass dich inspirieren von vielen tollen Geschenkideen für Mama, Papa, Oma und viel mehr!"
      />
      <HeadingTop
        title="Handverlesene Geschenkideen für Dich"
        subtitle="Alle Ideen im Überblick."
      />

      <div className="-mb-12" />
      <IdeaList
        title={false}
        event={event}
        person={person}
        displayFilter={true}
      />
      <EventList items={data.events.nodes} />
    </Layout>
  );
};

export default GeschenkPage;

export const query = graphql`
  query GeschenkideenQuery {
    persons: allContentfulPerson {
      nodes {
        childContentfulPersonTitleTextNode {
          title
        }
        icon {
          file {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    events: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        date(locale: "de", formatString: "DD. MMMM")
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
      }
    }
    nextEvent: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        title
        date(fromNow: true, locale: "de")
        image {
          file {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
